import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import { Button, Typography } from "@material-ui/core";
import ProgressBar from "../ProgressBar/ProgressBar";
import { IMetaState, IValueState, OptionalTooltip } from "../ControlBar/ControlBar";
import { IField } from "../../interfaces";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import Field from "../ControlBar/Field";
import { TestState } from "../MainContent/MainContent";
import CompanionModal from "../CompanionModal/companionModal";
import useExtensionTracker from "../../helpers/customHooks/useExtensionTracker";
import { useTranslation } from "react-i18next";

interface OnboardingProps {
  testState?: TestState;
  setTestState?: Dispatch<SetStateAction<TestState>>;
  theme: any;
  onboardingClasses: any;
  startTest: () => void;
  values: IValueState;
  fields: IField[];
  meta: IMetaState;
  config: TestConfiguration;
  handleChange: (fieldName: string) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Onboarding = ({
  testState,
  setTestState,
  theme,
  onboardingClasses,
  startTest,
  values,
  fields,
  meta,
  config,
  handleChange,
}: OnboardingProps) => {
  const arePropsValid = testState && setTestState;

  if (!arePropsValid) return null;

  // Determine if companion is forced based on the config
  const isCompanionForced = config?.options?.companion === "force";
  // Use the useExtensionTracker hook to check for the extension
  const isExtensionInstalled = useExtensionTracker();
  // State to manage the visibility of the CompanionModal
  const [showCompanionModal, setShowCompanionModal] = useState(false);

  const handleStartTestClick = () => {
    if (isCompanionForced && !isExtensionInstalled) {
      setShowCompanionModal(true); // Show modal if companion is forced but not installed
    } else {
      startTest(); // Proceed with starting the test as normal
    }
  };

  const { t } = useTranslation(["tests"]);

  return (
    <div className={onboardingClasses.lobbyPage}>
      {testState === "initial" && (
        <div className={onboardingClasses.formSection}>
          <div className={onboardingClasses.formDesc}>
            <Typography variant="h1">{t("tests:onboarding.title")}</Typography>
            <Typography variant="subtitle2">{t("tests:onboarding.message")}</Typography>
          </div>
          <div className={onboardingClasses.formInputs}>
            {Object.keys(values).length > 0 &&
              fields.map((field) => {
                if (meta[field.name]?.hidden) {
                  return null;
                }
                if (config?.inviteFields && config.inviteFields[field.name]) {
                  values[field.name] = config.inviteFields[field.name];
                }
                return (
                  <OptionalTooltip
                    key={field.name}
                    title={values[field.name]}
                    show={meta[field.name]?.readonly}
                  >
                    <Field
                      currentTheme={theme}
                      name={field.name}
                      value={values[field.name]}
                      meta={meta[field.name]}
                      onChange={handleChange(field.name)}
                      config={config}
                      onboardingClasses={onboardingClasses}
                    />
                  </OptionalTooltip>
                );
              })}
          </div>
          <Button
            data-html2canvas-ignore
            id="start"
            onClick={handleStartTestClick}
            className={onboardingClasses.actionBtn}
          >
            {t("tests:onboarding.run")}
          </Button>
        </div>
      )}

      {testState === "inProgress" && <ProgressBar />}

      {/* Conditionally render the CompanionModal */}
      {showCompanionModal && <CompanionModal open={showCompanionModal} />}
    </div>
  );
};

export default Onboarding;
