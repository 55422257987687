import React from "react";
import { Button, FormControl, MenuItem, Select, Tooltip, withStyles } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { DownloadIcon, IdIcon, LanguageIcon, LogIcon, RerunIcon } from "../../assets/icons";
import { IMetaState, IValueState, OptionalTooltip } from "./ControlBar";
import Field from "./Field";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { TestStatusType } from "twillio-tests/core/TestResults";
import { IField } from "../../interfaces";
import { SummarizeIcon } from "../TestsContainer/Tests/NewTests/UI/Icons";
import CustomTooltip from "../TestsContainer/Tests/NewTests/UI/CustomTooltip";
import { useTranslation } from "react-i18next";

interface Props {
  classes: any;
  isReady: boolean;
  testIsLoading: boolean;
  uuid?: string;
  testStartTime: any;
  config: TestConfiguration;
  moreBtnDisabled: boolean;
  testStatus: TestStatusType;
  openLog: () => void;
  currentTheme: string;
  reloadClick: () => void;
  showControlButtons: boolean;
  downloadClick: () => void;
  isDownloadingReport: boolean;
  values: IValueState;
  fields: IField[];
  meta: IMetaState;
  showLanguageDropdown: boolean;
  supportedLanguages: string[];
  handleChangeLanguage: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  current_language: string;
}

const styles = (theme: any): any => ({
  controlWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    marginBottom: "16px",
    marginTop: "16px",
    width: "100%",
  },
  controlPannelBottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(1080)]: {
      flexDirection: "column",
      rowGap: "20px",
    },
  },
  dateText: {
    color: "#243544AD",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.17px",
    [theme.breakpoints.down(1080)]: {
      textAlign: "center",
    },
  },
  controlPanel: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    flexWrap: "wrap",
    [theme.breakpoints.down(1080)]: {
      justifyContent: "center",
    },
  },
  overrideMuiBtn: {
    padding: 0,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "&:disabled": {
      backgroundColor: "transparent",
    },
  },
  logBtn: {
    width: "32px",
    maxWidth: "32px",
    minWidth: "32px",
    height: "32px",
    position: "relative",
  },
  logBtnBadge: {
    width: "8px",
    height: "8px",
    borderRadius: "9999px",
    position: "absolute",
    top: 0,
    right: 0,
  },
  outlinedBtn: {
    padding: "6px 12px",
    border: "1px solid #54718980",
    borderRadius: "4px",
  },
  btnLabel: {
    color: "#547189",
    marginLeft: "8px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
  },
  formInputs: {
    display: "flex",
    flexWrap: "wrap",
    gap: "24px",
    maxWidth: "424px",
    "& input": {
      [theme.breakpoints.up(1080)]: {
        width: "200px",
      },
      padding: "5.5px 0",
      fontFamily: "Roboto, sans-serif !important",
    },
    "& label": {
      fontFamily: "Roboto, sans-serif !important",
    },
  },
  noHoverBtn: {
    "& hover": {
      background: "transparent",
      shadow: "none",
    },
  },
});

const NewControlBar = (props: Props) => {
  const {
    classes,
    isReady,
    testIsLoading,
    uuid,
    testStartTime,
    config,
    moreBtnDisabled,
    testStatus,
    openLog,
    currentTheme,
    reloadClick,
    showControlButtons,
    downloadClick,
    isDownloadingReport,
    values,
    fields,
    meta,
    showLanguageDropdown,
    supportedLanguages,
    handleChangeLanguage,
    current_language,
  } = props;

  const { t } = useTranslation(["tests"]);

  return (
    <div className={classes.controlWrapper}>
      <div className={classes.controlPannelBottom} data-html2canvas-ignore>
        <div className={classes.formInputs}>
          {Object.keys(values).length > 0 &&
            fields.map((field) => {
              if (meta[field.name].hidden) {
                return null;
              }
              if (config?.inviteFields && config.inviteFields[field.name]) {
                values[field.name] = config.inviteFields[field.name];
              }
              return (
                <OptionalTooltip
                  key={field.name}
                  title={values[field.name]}
                  show={meta[field.name].readonly}
                >
                  <Field
                    name={field.name}
                    value={values[field.name]}
                    meta={meta[field.name]}
                    config={config}
                  />
                </OptionalTooltip>
              );
            })}
        </div>
        <div data-html2canvas-ignore className={classes.controlPanel}>
          {showControlButtons && (
            <CustomTooltip title={t("tests:resultPage.logs-tooltip")}>
              <Button
                id="log"
                variant="text"
                className={`${classes.logBtn} ${classes.overrideMuiBtn}`}
                disabled={!config?.debug && (moreBtnDisabled || config?.logRestrictAccess)}
                onClick={openLog}
              >
                <SummarizeIcon
                  disabled={!config?.debug && (moreBtnDisabled || config?.logRestrictAccess)}
                />
                <span
                  className={classes.logBtnBadge}
                  style={{
                    backgroundColor:
                      testStatus === "yellow" ? "#FFB33F" : testStatus === "red" ? "#D32F2F" : "",
                  }}
                />
              </Button>
            </CustomTooltip>
          )}
          {isReady && uuid && (
            <CustomTooltip title={t("tests:resultPage.copy-tooltip")}>
              <Button
                className={`${classes.overrideMuiBtn} ${classes.outlinedBtn}`}
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(uuid as string);
                }}
              >
                <IdIcon />
                <span className={classes.btnLabel}>{uuid}</span>
              </Button>
            </CustomTooltip>
          )}
          {showControlButtons && currentTheme !== "dialpad" && (
            <Button
              id="reload"
              className={`${classes.overrideMuiBtn} ${classes.outlinedBtn}`}
              variant="outlined"
              disabled={moreBtnDisabled}
              onClick={reloadClick}
            >
              <RerunIcon />
              <span className={classes.btnLabel}>{t("tests:resultPage.rerun")}</span>
            </Button>
          )}
          {showControlButtons && !config?.hideDownloadButton && (
            <CustomTooltip title={t("tests:resultPage.download-tooltip")}>
              <Button
                id="download"
                variant="text"
                className={`${classes.logBtn} ${classes.overrideMuiBtn}`}
                disabled={moreBtnDisabled || isDownloadingReport}
                onClick={downloadClick}
              >
                <DownloadIcon />
              </Button>
            </CustomTooltip>
          )}
          {showLanguageDropdown && supportedLanguages.length > 0 && (
            <FormControl className={classes.noHoverBtn}>
              <Select
                variant="standard"
                disableUnderline
                value={current_language}
                onChange={handleChangeLanguage}
                renderValue={() => <LanguageIcon />}
                IconComponent={() => null}
              >
                {supportedLanguages.map((lng: string, key: number) => {
                  return (
                    <MenuItem key={key} value={lng}>
                      {config?.languageTitles[lng]}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(NewControlBar);
